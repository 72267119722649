<script lang="ts" setup>

import {computed} from "vue";
import {useLanguage} from "@/composible/language";
import {VMenuLanguages} from "@/langs/VMenu.lang";

interface MenuItem {
	to : string,
	name : string,
	icon : string
}

const lang = useLanguage(VMenuLanguages);

let menu = computed<MenuItem[]>(() => [
	{
		to : '/earn',
		icon : 'earn',
		name : lang.value.menu.earn
	},
	{
		to : '/boosts',
		icon : 'boost',
		name : lang.value.menu.boost
	},
	{
		to : '/friends',
		icon : 'friends',
		name : lang.value.menu.friends
	}
]);

</script>

<template>
	<nav class="menu">
		<router-link :to="item.to" class="menu__item" v-for="item in menu" :key="item.name">
			<i class="menu__item-icon" :class="`menu__item-icon--${item.icon}`"></i>
			<span class="menu__item-name">{{ item.name }}</span>
		</router-link>
	</nav>
</template>