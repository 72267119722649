import {defineStore} from "pinia";
import {onMounted, ref} from "vue";
import {User} from "@/consts/models";
import {GameMainInfo} from "@/consts/other";
import {useTimeMachine} from "@/composible/time-machine";
import TelegramWebApp from "@/telegram/TelegramWebApp";
import Api from "@/modules/Api";
import {useDebounce} from "@/composible/debounce";

export const useGameStore = defineStore('game-store', () => {

	const timeMachine = useTimeMachine();

	let api = new Api(process.env.VUE_APP_API_DOMAIN);

	let ready = ref<boolean>(false);
	let authorized = ref<boolean>(false);
	let backgroundUpdate = ref<boolean>(true);

	let user = ref<User | null>(null);
	let game = ref<GameMainInfo | null>(null);

	let tapEventDebounce = useDebounce(700);

	onMounted(async function(){

		try {

			let info = await api.login(
				(TelegramWebApp.authorized ? TelegramWebApp.app!.initData : process.env.VUE_APP_FAKE_USER)
			);

			api.setToken(info.token);

			user.value = await api.me();
			game.value = info.game;

			authorized.value = true;
			ready.value = true;

		}catch (e){
			console.log('Error auth');
		}

	});

	timeMachine.interval(async () => {

		if(authorized.value) {

			let data = await api.me();

			if(backgroundUpdate.value){
				user.value = data;
			}

		}

	}, 2000);

	return {
		api,
		authorized,
		tapEventDebounce,
		ready,
		game,
		user,
		disableBackgroundUpdate(){
			backgroundUpdate.value = false;
		},
		enableBackgroundUpdate(){
			backgroundUpdate.value = true;
		}
	}

});