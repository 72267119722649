<script lang="ts" setup>

import {Task} from "@/consts/models";
import {ref} from "vue";
import {useGameStore} from "@/stores/game-store";
import {useLanguage} from "@/composible/language";
import {VTaskLanguages} from "@/langs/VTask.lang";
import {useTimeMachine} from "@/composible/time-machine";
import TelegramWebApp from "@/telegram/TelegramWebApp";
import TaskTypes from "@/consts/task-types";

interface TaskProps{
	task : Task
}

const gameStore = useGameStore();
const timeMachine = useTimeMachine();
const lang = useLanguage(VTaskLanguages);

const props = withDefaults(defineProps<TaskProps>(), {});

let startTime = ref<number>(0),
	isTaskActive = ref<boolean>(false),
	isCheckStarted = ref<boolean>(false),
	isError = ref<boolean>(false),
	isSuccess = ref<boolean>(false);

function onButtonStart(){

	isError.value = false;
	startTime.value = Date.now();
	isTaskActive.value = true;

	if(TelegramWebApp.authorized) {
		if (props.task.type === TaskTypes.STORY) {
			TelegramWebApp.app!.shareToStory(props.task.url);
		} else if (props.task.type === TaskTypes.TELEGRAM) {
			TelegramWebApp.app!.openTelegramLink(props.task.url);
		} else {
			TelegramWebApp.app!.openLink(props.task.url);
		}
	}else{
		window.open(props.task.url);
	}

}

function onButtonCheck(){

	if(isCheckStarted.value){
		return;
	}

	isCheckStarted.value = true;

	timeMachine.timeout(async () => {

		if(!props.task.timeout || startTime.value + props.task.timeout * 1000 < Date.now()){

			try {
				let check = await gameStore.api.taskCheck(props.task.id);

				if(!check){
					throw new Error('Bad');
				}

				isSuccess.value = true;
				isCheckStarted.value = false;
				isTaskActive.value = false;

				gameStore.user!.balance += props.task.reward;

			}catch (e){
				isError.value = true;
				isCheckStarted.value = false;
				isTaskActive.value = false;
			}

		}else{
			isError.value = true;
			isTaskActive.value = false;
			isCheckStarted.value = false;
		}

	}, 1000);



}

</script>

<template>
	<article class="tasks__item task">
		<img :src="props.task.picture" class="task__icon" alt="">
		<div class="task__body">
			<p class="task__h">{{ props.task.title}} <span class="task__price">+{{ props.task.reward }}</span></p>
			<p class="task__description" v-if="isError">{{ lang.error }}</p>
			<p class="task__description task__description--info" v-else-if="isCheckStarted">{{ lang.waiting }}</p>
			<p class="task__description" v-else>{{ props.task.description }}</p>
		</div>
		<button class="task__button button button--yellow button--small" v-if="isSuccess">{{ lang.buttons.success }}</button>
		<template v-else-if="isTaskActive">
			<button class="task__button button button--blue button--small" v-if="isCheckStarted">{{ lang.buttons.wait }}</button>
			<button class="task__button button button--blue button--small" @click.prevent="onButtonCheck" v-else>{{ lang.buttons.check }}</button>
		</template>
		<button class="task__button button button--green button--small" @click.prevent="onButtonStart" v-else>{{ lang.buttons.start }}</button>
	</article>
</template>