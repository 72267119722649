import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	title : string,
	hint : string,
	empty : string,
	loading : string
}

export const VTasksLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Заработок',
		hint : 'Ежедневные задания',
		empty : 'Пусто',
		loading : 'Загрузка...'
	},
	[Languages.EN] : {
		title : 'EARN screen',
		hint : 'DAILY TASK',
		empty : 'Empty',
		loading : 'Loading...'
	}
}