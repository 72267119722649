<script lang="ts" setup>

import VSpinePlayer from "@/components/Utility/SpinePlayer/VSpinePlayer.vue";
import {useBackgroundStore} from "@/stores/background-store";
import {ref, watch} from "vue";

const backgroundStore = useBackgroundStore();
const emit = defineEmits(['loaded']);

let backgroundLoaded = ref<boolean>(false),
	personLoaded = ref<boolean>(false);

watch(() => backgroundLoaded.value && personLoaded.value, () => emit('loaded'));

</script>
<template>
	<main class="background">
		<div class="background__rain"></div>
		<VSpinePlayer
			atlas="/assets/animations/bg/work_02.atlas"
			skeleton="/assets/animations/bg/work_02.json"
			animation="animation"
			loop
			@loaded="backgroundLoaded = true"
			class="background__background" />
		<VSpinePlayer
			atlas="/assets/animations/durov/work_02.atlas"
			skeleton="/assets/animations/durov/work_02.json"
			animation="animation"
			loop
			@loaded="personLoaded = true"
			class="background__person" />
		<div class="background__jail jail">
			<div class="jail__item" v-for="k in 4" :key="k">
				<TransitionGroup name="fade">
					<div
						v-for="element in backgroundStore.destroyElements.filter(element => element.n === k)"
						:key="`${element.n}_${element.v}`"
						class="jail__item-damaged"
						:class="`jail__item-damaged--view-${element.v}`"
					></div>
				</TransitionGroup>
			</div>
		</div>
	</main>
</template>
