<script lang="ts" setup>

import Modal, {ModalProps} from "@/components/Utility/Modal/VModal.vue";
import {useGameStore} from "@/stores/game-store";
import {computed} from "vue";
import {useLanguage} from "@/composible/language";
import {VModalBuyEnergyLanguages} from "@/langs/VModalBuyEnergy.lang";
import TelegramWebApp from "@/telegram/TelegramWebApp";

const gameStore = useGameStore();
const lang = useLanguage(VModalBuyEnergyLanguages);

const props = withDefaults(defineProps<Pick<ModalProps, 'active'>>(), {});

let items = computed(() => gameStore.game!.energy.market);

async function buy(recharges : number){

	try{

		let invoice = await gameStore.api.marketInvoice(recharges);

		onInvoiceReady(invoice);

	}catch (e){

	}

}

function onInvoiceReady(json : any){

	if(TelegramWebApp.authorized){
		TelegramWebApp.app!.openInvoice(json.link);
	}else{
		window.open(json.link);
	}

}


</script>
<template>
	<Modal v-bind="props" :title="lang.title" :size="items.length > 4 ? 'big' : 'middle'">
		<div class="energy-market">
			<article class="energy-market__item" v-for="(item,i) in items" :key="item.cost">
				<i class="energy-market__item-icon">
					<i v-for="k in (i > 1 ? 3 : i > 0 ? 2 : 1)"></i>
				</i>
				<p class="energy-market__item-h">x{{ item.recharges }}</p>
				<button class="energy-market__item-button button button--small button--green" @click.prevent="buy(item.recharges)">{{ item.cost }}</button>
			</article>
		</div>
	</Modal>
</template>