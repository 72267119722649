<script setup lang="ts">

import VModal, {ModalProps} from "@/components/Utility/Modal/VModal.vue";
import {useLanguage} from "@/composible/language";
import {VModalNoFundsLanguages} from "@/langs/VModalNoFunds.lang";

const lang = useLanguage(VModalNoFundsLanguages);

const props = withDefaults(defineProps<Pick<ModalProps, 'active'>>(), {});

</script>
<template>
	<VModal v-bind="props" size="normal">
		<div class="no-funds">
			<p class="no-funds__h">{{ lang.title }}</p>
			<p class="no-funds__hint">{{ lang.hint }}</p>
			<p class="no-funds__panel" v-html="lang.panel(50000)"></p>
			<router-link to="/friends" class="no-funds__button button button--big button--green">{{lang.button }}</router-link>
		</div>
	</VModal>
</template>
