import Languages from "@/consts/languages";
import {computed} from "vue";
import {useGameStore} from "@/stores/game-store";

export interface LangDataFunction{
	(...args : any[]) : string
}

export interface LangData{
	[key:string] : LangData | string | LangDataFunction
}

export interface LangList<T extends LangData> extends Record<typeof Languages[keyof typeof Languages], T>{
}

export function useLanguage<T extends LangData>(languages : LangList<T>){

	const gameStore = useGameStore();

	return computed<T>(() => languages[gameStore.user ? gameStore.user.lang : Languages.EN]);

}