<script lang="ts" setup>

import {ref, watch} from "vue";
import {useDebounce} from "@/composible/debounce";

export interface ModalProps{
	size : 'big' | 'small' | 'middle' | 'normal',
	active : boolean,
	title? : string,
	backgroundClickClose? : boolean
}

const emit = defineEmits(['close']);

const props = withDefaults(defineProps<ModalProps>(), {
	size : 'middle',
	title : '',
	backgroundClickClose : true
});

let show = ref<boolean>(false);

let activeDebounce = useDebounce(500);

function close(){
	emit('close');
}

function onBackgroundClick(){
	props.backgroundClickClose && close();
}

watch(() => props.active, (value) => {

	if(value){
		show.value = value;
	}else{

		activeDebounce(() => {

			if(!props.active){
				show.value = false;
			}

		});

	}


});

</script>
<template>
	<Teleport to="body">
		<aside class="modal" v-show="show">
			<Transition name="fade">
				<div tabindex="0" class="modal__background background" v-if="active" @click.prevent="onBackgroundClick"></div>
			</Transition>
			<div class="modal__container">
				<Transition name="modal">
					<div class="modal__body" v-if="active" :class="`modal__body--${props.size}`">
						<button class="modal__close" @click.prevent="close"></button>
						<p class="modal__title" v-if="props.title">{{ props.title }}</p>
						<slot></slot>
					</div>
				</Transition>
			</div>
		</aside>
	</Teleport>
</template>