<script lang="ts" setup>

import {useGameStore} from "@/stores/game-store";
import {computed, ref} from "vue";
import VModalComingSoon from "@/components/Modals/VModalComingSoon.vue";
import VModalBuyEnergy from "@/components/Modals/VModalBuyEnergy.vue";
import VModalNoFunds from "@/components/Modals/VModalNoFunds.vue";
import Formatter from "../../modules/Formatter";
import {useLanguage} from "@/composible/language";
import {VBoostLanguages} from "@/langs/VBoost.lang";
import {useClickHints} from "@/composible/click-hints";

const gameStore = useGameStore();
const clickHints = useClickHints();
const lang = useLanguage(VBoostLanguages);

let comingSoonModalShow = ref<boolean>(false);
let marketModalShow = ref<boolean>(false);
let noFundsModalShow = ref<boolean>(false);

let freeBonusUptime = computed<string>(() => {

	let interval = gameStore.game!.energy.freeChargeBonusInterval;

	if(interval >= 60 * 60){

		return lang.value.bonus.value(
			Math.ceil(interval / (60 * 60)),
			true
		);

	}else{

		return lang.value.bonus.value(
			Math.ceil(interval / 60),
			false
		);

	}

});

let nextLevel = computed(() => {
	return gameStore.user!.energy_level < gameStore.game!.energy.levelUps.length
		? gameStore.game!.energy.levelUps[gameStore.user!.energy_level - 1]
		: null;
});

async function getFreeEnergyBonus(e : MouseEvent){

	clickHints.push(e.clientX - 40, e.clientY - 40, lang.value.bonus.clicked, 20);

	gameStore.user!.energy_bonus_available = false;

	try {
		await gameStore.api.energyBonusRecharge();
	}catch (e){
		gameStore.user!.energy_bonus_available = true;
	}

}

async function levelUpEnergy(e){

	let next = nextLevel.value!;

	if(gameStore.user!.balance >= next.cost) {

		clickHints.push(e.clientX - 20, e.clientY - 40, 'Upgrade!', 20);

		gameStore.user!.balance -= next.cost;
		gameStore.user!.energy_level++;

		try{
			await gameStore.api.energyLevelUp();
		}catch(e){
			gameStore.user!.energy_level--;
			gameStore.user!.balance += next.cost;
		}

	}else{
		noFundsModalShow.value = true;
	}

}

</script>
<template>
	<section class="boosts background">
		<p class="boosts__balance">{{ Formatter.numberFormat(gameStore.user!.balance, 0) }}</p>
		<h2 class="boosts__hint">{{ lang.title }}</h2>
		<div class="boosts__tools">
			<button class="boosts__button boosts-button button button--blue" @click.prevent="comingSoonModalShow = true">
				<i class="boosts-button__icon boosts-button__icon--rocket"></i>
				<span class="boosts-button__h">{{ lang.rocket.title }}</span>
				<span class="boosts-button__hint">3/3</span>
			</button>
			<button class="boosts__button boosts-button button button--yellow" :disabled="!gameStore.user!.energy_bonus_available" @click.prevent="getFreeEnergyBonus">
				<i class="boosts-button__icon boosts-button__icon--energy"></i>
				<span class="boosts-button__h">{{ lang.bonus.title }}</span>
				<span class="boosts-button__hint">{{ freeBonusUptime }}</span>
			</button>
		</div>
		<h1 class="boosts__h">{{ lang.title }}</h1>
		<div class="boosts__body">
			<button class="boost boosts__item" @click.prevent="marketModalShow = true">
				<i class="boost__icon boost__icon--energy"></i>
				<span class="boost__h">{{ lang.market.title }}</span>
				<span class="boost__hint">{{ lang.market.hint(8) }}</span>
			</button>
			<button class="boost boosts__item"
			        :class="{ 'boost--disabled' : gameStore.user!.balance < nextLevel.cost }"
			        @click.prevent="levelUpEnergy"
			        v-if="nextLevel">
				<i class="boost__icon boost__icon--energy"></i>
				<span class="boost__h">{{ lang.level_up.title }}</span>
				<span class="boost__hint"><b class="boost__price">{{ nextLevel.cost }}</b>&nbsp;&nbsp;&nbsp; {{ lang.level_up.level(gameStore.user!.energy_level) }}</span>
			</button>
			<button class="boost boosts__item" @click.prevent="comingSoonModalShow = true">
				<i class="boost__icon boost__icon--bot"></i>
				<span class="boost__h">{{ lang.bot.title }}</span>
				<span class="boost__hint"><b class="boost__price">2500</b></span>
			</button>
		</div>
	</section>
	<VModalComingSoon :active="comingSoonModalShow" @close="comingSoonModalShow = false" />
	<VModalBuyEnergy :active="marketModalShow" @close="marketModalShow = false" />
	<VModalNoFunds :active="noFundsModalShow" @close="noFundsModalShow = false" />
</template>