<script lang="ts" setup>

import {useGameStore} from "@/stores/game-store";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useDebounce} from "@/composible/debounce";
import {useThrottler} from "@/composible/throttler";
import {useClickHints} from "@/composible/click-hints";
import {useTimeMachine} from "@/composible/time-machine";
import {useBackgroundStore} from "@/stores/background-store";
import VEnergy from "@/components/Game/VEnergy.vue";
import VBalance from "@/components/Game/VBalance.vue";
import VModalBuyEnergy from "@/components/Modals/VModalBuyEnergy.vue";
import VModalNoEnergy from "@/components/Modals/VModalNoEnergy.vue";

interface ExplosionElement{
	x : number,
	y : number,
	id : number,
}

const gameStore = useGameStore();
const clickHints = useClickHints();
const timeMachine = useTimeMachine();
const backgroundStore = useBackgroundStore();

let explosionElements   = ref<ExplosionElement[]>([]);

let unCommitTaps = ref<number>(0);

let destroySpawnThrottler = useThrottler(1000),
	destroyClearDebounce = useDebounce(2000),
	explosionSpawnThrottler = useThrottler(800);

let showMarketModal = ref<boolean>(false),
	showNoEnergyModal = ref<boolean>(false);


function onGameClick(event : MouseEvent | Touch, exp : number = 1){

	if(gameStore.user!.energy < gameStore.game!.energy.spendRate * exp){
		showNoEnergyModal.value = true;
		return;
	}

	gameStore.user!.energy -= gameStore.game!.energy.spendRate * exp;
	gameStore.user!.balance += gameStore.game!.energy.rewardRate * exp;

	destroySpawnThrottler(() => {
		backgroundStore.addDestroyElement();
	});

	destroyClearDebounce(() => {
		backgroundStore.clearDestroyElements();
	});

	explosionSpawnThrottler(() => {

		let explosion = {
			x : event.clientX,
			y : event.clientY,
			id : Date.now()
		};

		explosionElements.value.push(explosion);

		timeMachine.timeout(() => {
			explosionElements.value.splice(
				explosionElements.value.indexOf(explosion), 1
			);
		}, 700);

	});

	clickHints.push(
		event.clientX - 10,
		event.clientY - 10,
		'+' + (gameStore.game!.energy.rewardRate * exp),
		40
	);

	commitNewTap(exp);

}

function onGameTap(e : TouchEvent){

	let touches = [...e.touches];

	if(touches.length >= 3){
		touches.forEach(touch => onGameClick(touch, 2));
	}else{
		touches.forEach(touch => onGameClick(touch, 1));
	}

}

function commitNewTap(exp : number = 1){

	unCommitTaps.value += exp;

	gameStore.tapEventDebounce(async () => {

		let count = unCommitTaps.value;

		unCommitTaps.value = 0;

		await gameStore.api.tap(count);

	});

}

onMounted(() => {
	gameStore.disableBackgroundUpdate();
});

onBeforeUnmount(() => {
	gameStore.enableBackgroundUpdate();
});

</script>
<template>
	<main class="game">
		<div class="game__zone" @click.prevent="onGameClick" @touchstart="onGameTap"></div>
		<VEnergy class="game__energy" />
		<VBalance class="game__balance" />
		<div class="game__taps">
			<div class="game__taps-item tap"
			     v-for="tap in explosionElements"
			     :key="tap.id"
			     :style="{ top : tap.y + 'px', left : tap.x + 'px' }">
			</div>
		</div>
		<VModalNoEnergy @open-market="showNoEnergyModal = false;showMarketModal = true;" :active="showNoEnergyModal" @close="showNoEnergyModal = false" />
		<VModalBuyEnergy :active="showMarketModal" @close="showMarketModal = false" />
	</main>
</template>
