<script setup lang="ts">

import VModal, {ModalProps} from "@/components/Utility/Modal/VModal.vue";
import {useLanguage} from "@/composible/language";
import {VModalSettingsLanguages} from "@/langs/VModalSettings.lang";
import Languages from "@/consts/languages";
import {useGameStore} from "@/stores/game-store";
import {useClickHints} from "@/composible/click-hints";

const gameStore = useGameStore();
const clickHints = useClickHints();
const lang = useLanguage(VModalSettingsLanguages);

const props = withDefaults(defineProps<Pick<ModalProps, 'active'>>(), {});

let languages = Object.values(Languages);

async function setLanguage(e : MouseEvent, code : string){

	clickHints.push(e.clientX - 20, e.clientY - 20, 'Switch!');

	gameStore.user!.lang = code;

	try {
		await gameStore.api.setLanguage(code);
	}catch (e){
	}

}

</script>
<template>
	<VModal v-bind="props" size="normal" :title="lang.title">
		<div class="settings">
			<div class="settings__group settings-group">
				<h2 class="settings-group__h">{{ lang.languages }}</h2>
				<div class="languages settings-group__body">
					<button class="languages__item"
					        :class="{[`languages__item--${language}`] : true, 'languages__item--disabled' : gameStore.user!.lang !== language }"
					        v-for="language in languages"
					        :key="language"
					        @click.prevent="setLanguage($event, language)"
					>
					</button>
				</div>
			</div>
		</div>
	</VModal>
</template>
