import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	title : string
}

export const VModalBuyEnergyLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Пополнить энергию'
	},
	[Languages.EN] : {
		title : 'Add energy'
	}
};