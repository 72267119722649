import {TelegramWebApps} from "telegram-webapps-types";
import WebApp = TelegramWebApps.WebApp;

interface BackButton{
	readonly isVisible : boolean,
	onClick(fn : () => void) : void,
	show() : void,
	hide() : void
}

interface WebAppNew extends WebApp{

	platform : string

	BackButton : BackButton,

	openInvoice(url : string) : void;
	openTelegramLink(url : string) : void;
	openLink(url : string) : void;
	shareToStory(url : string) : void;

}

let app : null | WebAppNew = ('Telegram' in window) ? (<any>window).Telegram.WebApp : null;

export default {
	initiated : !!app,
	authorized :  app && app.initData,
	isMobile : app && (app.platform === 'ios' || app.platform === 'android' || process.env.NODE_ENV == 'development'),
	app : app
}