<script lang="ts" setup>

import {useClickHintsStore} from "@/stores/click-hints-store";

const hints = useClickHintsStore();

</script>
<template>
	<div class="click-hints">
		<div class="click-hints__item"
		     tabindex="-1"
		     v-for="item in hints.list"
		     :key="item.id"
		     :style="{ top : item.y + 'px', left : item.x + 'px', fontSize : item.size + 'px' }">
			{{ item.text }}
		</div>
	</div>
</template>