import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	title : string,
	languages : string
}

export const VModalSettingsLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Настройки',
		languages : 'Язык'
	},
	[Languages.EN] : {
		title : 'Settings',
		languages : 'Language'
	}
}