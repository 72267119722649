<script setup lang="ts">
import {useGameStore} from "@/stores/game-store";
import VClickHints from "@/components/Utility/ClickHints/VClickHints.vue";
import VLoader from "@/components/Loader/VLoader.vue";
import {computed, ref, watch} from "vue";
import VBackground from "@/components/Background/VBackground.vue";
import VResourcesLoader from "@/components/Utility/ResourcesLoader/VResourcesLoader.vue";
import img from "@/components/Utility/ResourcesLoader/img.vue";
import {useDevelopment} from "@/composible/development";
import TelegramWebApp from "@/telegram/TelegramWebApp";
import VQR from "@/components/QR/VQR.vue";

const gameStore = useGameStore();
const development = useDevelopment();

let slidesShowed = ref<number>(0);

let backgroundReady = ref<boolean>(false),
	resourcesReady = ref<boolean>(false),
	sliderReady = computed<boolean>(() => !development.needShowLoader || slidesShowed.value >= 3);

let ready = computed<boolean>(() => backgroundReady.value && resourcesReady.value && sliderReady.value && gameStore.authorized);

watch(ready, () => {

	if(ready){
		TelegramWebApp.app!.ready();
	}

});

</script>

<template>
	<div class="wrap">
		<template v-if="TelegramWebApp.isMobile">
			<div class="wrap__game" v-if="gameStore.authorized">
				<VBackground @loaded="backgroundReady = true" />
				<RouterView />
			</div>
			<Transition name="fade">
				<VLoader @slide-showed="slidesShowed++" v-if="!ready" />
			</Transition>
		</template>
		<VQR v-else />
		<VClickHints />
		<VResourcesLoader @load="resourcesReady = true">
			<template #default="{ onLoad }">
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/bg-668.png">
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/bg-668.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/bg-887.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/bg-965.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/big.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/middle.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/normal.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/cards/small.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/bot.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/close.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/copy.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/close.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/energy.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/jail.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/langs.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/lighting.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/menu.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/play.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/rain.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/rocket.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/settings.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/star.svg" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/tap.gif" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/wallet.png" />
				<img @load="onLoad" @error="onLoad" src="@/assets/images/star-wallet.svg" />
			</template>
		</VResourcesLoader>
	</div>
</template>
