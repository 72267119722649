import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	title : string,
	hint : string,
	invite : {
		usual : {
			title : string
			hint : string
		},
		premium : {
			title : string,
			hint : string
		}
	},
	button : string,
	copy : string
}

export const VFriendsLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Друзей',
		hint : 'Пригласи друга и получи бонусы',
		invite : {
			usual : {
				title : 'Пригласи друга',
				hint : 'для тебя и твоего друга'
			},
			premium : {
				title : 'Пригласи с Telegram Premium',
				hint : 'для тебя и твоего друга'
			}
		},
		button : 'Пригласить друга',
		copy : 'Скопировано!'
	},
	[Languages.EN] : {
		title : 'Friends',
		hint : 'Invite friend and get bonus',
		invite : {
			usual : {
				title : 'Invite friend',
				hint : 'for you and your friend'
			},
			premium : {
				title : 'Invite friend with Premium',
				hint : 'for you and your friend'
			}
		},
		button : 'Invite friend',
		copy : 'Copied!'
	}
}