import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	hint : string,
	rocket : {
		title : string
	},
	bonus : {
		title : string,
		value : (v : number, isHours : boolean) => string,
		clicked : string
	},
	title : string,
	market : {
		title : string,
		hint : (v : number) => string
	},
	level_up : {
		title : string,
		level : (v : number) => string
	},
	bot : {
		title : string
	}
}

export const VBoostLanguages : LangList<Lang> = {

	[Languages.RU] : {
		hint : 'Бесплатные ежедневные бусты',
		rocket : {
			title : 'Ракета'
		},
		bonus : {
			title : 'Энергия',
			value : (v, isHours) => `раз в ${v} ${isHours ? 'час.' : 'мин.'}`,
			clicked : 'Восполнено!'
		},
		title : 'Усилители',
		market : {
			title : 'Пополнить энергию',
			hint : (v : number) => `x${v} зарядов`
		},
		level_up : {
			title : 'Лимит энергии',
			level : (v : number) => `уровень ${v}`
		},
		bot : {
			title : 'Tap Bot'
		}
	},


	[Languages.EN] : {
		hint : 'Free daily bonuses',
		rocket : {
			title : 'Rocket'
		},
		bonus : {
			title : 'Energy',
			value : (v, isHours) => `in ${v} ${isHours ? 'h.' : 'min.'}`,
			clicked : 'Done!'
		},
		title : 'Boosts',
		market : {
			title : 'Add energy',
			hint : (v : number) => `x${v} charges`
		},
		level_up : {
			title : 'Energy limit',
			level : (v : number) => `${v} level`
		},
		bot : {
			title : 'Tap Bot'
		}
	}


}