import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData{
	block1 : string,
	block2 : string,
	block3 : string
}

export const VLoaderLanguages : LangList<Lang> = {
	[Languages.EN] : {
		block1 : 'Help the founder gain his freedom. Start from the prison cells!',
		block2 : 'Upgrade freedom, money :wallet, strength, and defense. Every decision brings you closer to victory!',
		block3 : 'Build a powerful empire and become a legend!'
	},
	[Languages.RU] : {
		block1 : 'Помоги основателю обрести свободу. Начни с тюремных камер!',
		block2 : 'Прокачивай свободу, деньги :wallet, силу и защиту. Каждое решение приближает к победе!',
		block3 : 'Создай мощную империю и стань легендой!'
	}
}