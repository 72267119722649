import {useClickHintsStore} from "@/stores/click-hints-store";

export const useClickHints = () => {

	const store = useClickHintsStore();

	return {
		push(x : number, y : number, text : string, size : number = 14){
			store.push(x, y, text, size);
		}
	}

}