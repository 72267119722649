import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData
{
	error : string,
	waiting : string,
	buttons : {
		check : string,
		start : string,
		success : string,
		wait : string
	}
}

export const VTaskLanguages : LangList<Lang> = {
	[Languages.RU] : {
		error : 'Проверка не пройдена',
		waiting : 'Выполняется проверка...',
		buttons : {
			check : 'Check',
			start : 'GO',
			success : 'OK',
			wait : 'Wait..',
		}
	},
	[Languages.EN] : {
		error : 'Error check',
		waiting : 'Waiting confirmation...',
		buttons : {
			check : 'Check',
			start : 'GO',
			success : 'OK',
			wait : 'Wait..'
		}
	}
}