import {onBeforeUnmount, ref} from "vue";

interface TimeMachineCallback{
	() : void
}

export interface TimeMachineTimeoutResult{
	stop() : void;
}

export interface TimeMachineIntervalResult{
	stop() : void
}

class TimeMachineTimeout{

	protected time : number;
	protected id : number | null = null;

	public constructor(time : number) {
		this.time = time;
	}

	public start(fn : TimeMachineCallback) : this
	{
		this.id = setTimeout(fn, this.time);

		return this;
	}

	public stop() : this
	{

		if(this.id){
			clearTimeout(this.id);
			this.id = null;
		}

		return this;

	}

}

class TimeMachineInterval{

	protected time : number;
	protected id : number | null = null;

	public constructor(time : number) {
		this.time = time;
	}

	public start(fn : TimeMachineCallback) : this
	{
		this.id = setInterval(fn, this.time);

		return this;
	}

	public stop() : this
	{

		if(this.id){
			clearInterval(this.id);
			this.id = null;
		}

		return this;

	}

}

export const useTimeMachine = () => {

	let timeouts = ref<TimeMachineTimeout[]>([]),
		intervals = ref<TimeMachineInterval[]>([]);

	onBeforeUnmount(() => {
		timeouts.value.forEach(timeout => timeout.stop());
		intervals.value.forEach(interval => interval.stop());
	});

	return {
		interval(fn : () => void, time : number) : TimeMachineIntervalResult
		{

			let interval = new TimeMachineInterval(time);

			intervals.value.push(interval);

			interval.start(fn);

			let clear = () => {
				intervals.value.splice(
					intervals.value.indexOf(interval),
					1
				);
			}

			return {
				stop(){
					interval.stop();
					clear();
				}
			}

		},
		timeout(fn : () => void, time : number) : TimeMachineTimeoutResult
		{

			let timeout = new TimeMachineTimeout(time);

			timeouts.value.push(timeout);

			let clear = () => {
				timeouts.value.splice(
					timeouts.value.indexOf(timeout),
					1
				);
			}

			timeout.start(() => {
				clear();
				fn();
			});

			return {
				stop(){
					timeout.stop();
					clear();
				}
			}

		}
	}

}