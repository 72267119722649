<script setup lang="ts">
import VLoaderSlide from "@/components/Loader/VLoaderSlide.vue";
import {ref} from "vue";
import {useLanguage} from "@/composible/language";
import {VLoaderLanguages} from "@/langs/VLoader.lang";

interface Slide{
	text : string,
	picture : any,
	id : string
}

const emit = defineEmits(['slide-showed']);
const lang = useLanguage(VLoaderLanguages);

let slides = [
	{ text : lang.value.block1, picture : require('../../assets/images/slides/slide-1.png'), id : 'durov' },
	{ text : lang.value.block2, picture : require('../../assets/images/slides/slide-2.png'), id : 'sk' },
	{ text : lang.value.block3, picture : require('../../assets/images/slides/slide-3.png'), id : 'fly' }
];

let activeSlide = ref<number>(0);

function onShowed(){

	emit('slide-showed');

	if(activeSlide.value === slides.length - 1) {
		activeSlide.value = 0;
	}else{
		activeSlide.value++;
	}

}

</script>
<template>
	<aside class="loader">
		<div class="loader__slide" v-for="(slide,i) in slides" :key="slide.id">
			<VLoaderSlide :id="slide.id" :text="slide.text" :picture="slide.picture" @showed="onShowed" v-if="activeSlide === i" />
		</div>
	</aside>
</template>
