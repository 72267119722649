<script lang="ts" setup>

import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useGameStore} from "@/stores/game-store";
import VModalBuyEnergy from "@/components/Modals/VModalBuyEnergy.vue";
import {useClickHints} from "@/composible/click-hints";
import {useTimeMachine} from "@/composible/time-machine";

const gameStore = useGameStore();
const clickHints = useClickHints();
const timeMachine = useTimeMachine();

let showMarketModal = ref<boolean>(false);

let energyTimer = ref<number>(0);

let energyTimerText = computed<string>(() => {

	let time = energyTimer.value;

	if(!time){
		return '--:--';
	}

	let hours = Math.floor(time / (60 * 60));

	time -= hours * 60 * 60;

	let minutes = Math.floor(time / 60);

	time -= minutes * 60;

	let seconds = time;

	let n = (x : number) => x > 9 ? x : `0${x}`;

	// if(hours > 0){
		return n(hours) + ':' + n(minutes);
	// }else{
	// 	return n(minutes) + ':' + n(seconds);
	// }

});

let percent = computed<number>(() => {
	return gameStore.user!.energy / gameStore.user!.energy_max;
});

let dashArray = computed<string>(() => {
	return `${ percent.value * 510 }, 500`;
});

let gradient = computed<[string,string]>(() => {
	return percent.value > 0.3 ? ['#03DDF0', '#0095D0'] : ['#F06F03', '#D0BE00'];
});

async function rechargeEnergy(e : MouseEvent){

	if(gameStore.user!.energy_charges > 0){

		if(gameStore.user!.energy / gameStore.user!.energy_max <= 0.9){

			clickHints.push(e.clientX - 30, e.clientY - 30, 'Click!');

			gameStore.user!.energy_charges--;
			gameStore.user!.energy = gameStore.user!.energy_max;

			try{
				await gameStore.api.rechargeEnergy();
			}catch (e){}

		}

	}else{
		showMarketModal.value = true;
	}

}

watch(() => gameStore.user!.energy_auto_recharge_time, (v) => {
	energyTimer.value = v || 0;
}, {immediate : true});

</script>
<template>
	<button class="energy" @click.prevent="rechargeEnergy">
		<svg width="182" height="183" viewBox="0 0 182 183" fill="none" xmlns="http://www.w3.org/2000/svg" class="energy__icon">
			<mask id="path-1-inside-1_505_8" fill="white">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M90.6356 182.011C140.692 182.011 181.271 141.267 181.271 91.0055C181.271 40.7446 140.692 0 90.6356 0C40.5789 0 0 40.7446 0 91.0055C0 141.267 40.5789 182.011 90.6356 182.011ZM90.6352 159.075C128.842 159.075 159.814 128.434 159.814 90.6356C159.814 52.8377 128.842 22.1965 90.6352 22.1965C52.4287 22.1965 21.4562 52.8377 21.4562 90.6356C21.4562 128.434 52.4287 159.075 90.6352 159.075Z"/>
			</mask>
			<circle class="loader-path" cx="91" cy="91" r="80" fill="none" stroke="#9293B3" stroke-width="18" :stroke-dasharray="dashArray" />
			<path d="M179.271 91.0055C179.271 140.17 139.58 180.011 90.6356 180.011V184.011C141.804 184.011 183.271 142.363 183.271 91.0055H179.271ZM90.6356 2C139.58 2 179.271 41.8414 179.271 91.0055H183.271C183.271 39.6477 141.804 -2 90.6356 -2V2ZM2 91.0055C2 41.8414 41.6912 2 90.6356 2V-2C39.4667 -2 -2 39.6477 -2 91.0055H2ZM90.6356 180.011C41.6912 180.011 2 140.17 2 91.0055H-2C-2 142.363 39.4667 184.011 90.6356 184.011V180.011ZM157.814 90.6356C157.814 127.309 127.758 157.075 90.6352 157.075V161.075C129.926 161.075 161.814 129.558 161.814 90.6356H157.814ZM90.6352 24.1965C127.758 24.1965 157.814 53.9625 157.814 90.6356H161.814C161.814 51.7129 129.926 20.1965 90.6352 20.1965V24.1965ZM23.4562 90.6356C23.4562 53.9625 53.513 24.1965 90.6352 24.1965V20.1965C51.3445 20.1965 19.4562 51.7129 19.4562 90.6356H23.4562ZM90.6352 157.075C53.513 157.075 23.4562 127.309 23.4562 90.6356H19.4562C19.4562 129.558 51.3445 161.075 90.6352 161.075V157.075Z" fill="#4A4C80" mask="url(#path-1-inside-1_505_8)"/>
			<path d="M158.814 90.6354C158.814 127.871 128.299 158.075 90.6351 158.075C52.9707 158.075 22.4561 127.871 22.4561 90.6354C22.4561 53.3999 52.9707 23.1963 90.6351 23.1963C128.299 23.1963 158.814 53.3999 158.814 90.6354Z" fill="url(#paint0_linear_505_8)" stroke="#1E227C" stroke-width="2"/>
			<g filter="url(#filter0_d_505_8)" v-if="gameStore.user!.energy > 0">
				<path d="M121.639 77.0795C121.488 76.7645 121.252 76.4989 120.959 76.3131C120.666 76.1273 120.327 76.0289 119.982 76.029H100.796L116.122 43.2691C116.255 42.985 116.315 42.6714 116.296 42.3575C116.277 42.0436 116.18 41.7397 116.014 41.474C115.848 41.2083 115.618 40.9895 115.346 40.838C115.074 40.6865 114.768 40.6072 114.458 40.6074H84.9976C84.6425 40.6074 84.2949 40.7113 83.9967 40.9067C83.6986 41.1022 83.4626 41.3807 83.3173 41.7088L59.3809 95.7733C59.2552 96.0572 59.2016 96.3685 59.2251 96.6787C59.2486 96.989 59.3485 97.2884 59.5155 97.5495C59.6826 97.8107 59.9115 98.0254 60.1815 98.174C60.4515 98.3227 60.754 98.4005 61.0613 98.4005H79.0305L70.3097 139.018C70.2208 139.43 70.2725 139.861 70.4565 140.239C70.6405 140.618 70.9458 140.922 71.3226 141.102C71.6994 141.282 72.1254 141.327 72.5309 141.23C72.9365 141.134 73.2976 140.901 73.5553 140.569L121.428 79.0474C121.642 78.7724 121.775 78.442 121.812 78.0941C121.849 77.7461 121.789 77.3945 121.639 77.0795Z" fill="url(#paint1_linear_505_8)"/>
			</g>
			<mask id="path-5-outside-2_505_8" maskUnits="userSpaceOnUse" x="84" y="103" width="40" height="32" fill="black">
				<rect fill="white" x="84" y="103" width="40" height="32"/>
				<path d="M94.0488 112.924L97.6299 119.307L101.267 112.924H106.592L100.691 122.758L106.833 133H101.508L97.667 126.302L93.8076 133H88.4824L94.6055 122.758L88.7422 112.924H94.0488ZM121.788 105.91V133H116.871V111.644L110.358 113.796V109.825L121.231 105.91H121.788Z"/>
			</mask>
			<defs>
				<filter id="filter0_d_505_8" x="59.2197" y="40.6074" width="62.6035" height="105.673" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="5"/>
					<feComposite in2="hardAlpha" operator="out"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.447059 0 0 0 0 0.541176 0 0 0 1 0"/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_505_8"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_505_8" result="shape"/>
				</filter>
				<linearGradient id="paint0_linear_505_8" x1="108" y1="-14" x2="91" y2="683.5" gradientUnits="userSpaceOnUse">
					<stop stop-color="#84817E"/>
					<stop offset="0.613508" stop-color="#1E1D1D"/>
				</linearGradient>
				<linearGradient id="paint1_linear_505_8" x1="90.5213" y1="40.6074" x2="90.5213" y2="141.28" gradientUnits="userSpaceOnUse">
					<stop :stop-color="gradient[0]"/>
					<stop offset="1" :stop-color="gradient[1]"/>
				</linearGradient>
			</defs>
		</svg>
		<span v-if="!gameStore.user!.energy" class="energy__timer">{{ energyTimerText }}</span>
		<span class="energy__count">x{{ gameStore.user!.energy_charges }}</span>
		<VModalBuyEnergy :active="showMarketModal" @close="showMarketModal = false" />
	</button>
</template>
<style scoped>
.loader-path{
	transform-origin:center;
	transform: rotate(-90deg);
	transition: all 1s;
}
</style>