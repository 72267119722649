<script lang="ts" setup>

import {useLanguage} from "@/composible/language";
import {VQRLanguages} from "@/langs/VQR.lang";

const lang = useLanguage(VQRLanguages);

</script>
<template>
	<aside class="qr">
		<p class="qr__title">{{ lang.title }}</p>
		<div class="qr__code">
			<img src="@/assets/images/qr.png" class="qr__code-img" alt="">
		</div>
	</aside>
</template>