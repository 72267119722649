import axios, {AxiosError} from "axios";

interface ApiSuccessResponse{
	status : true,
	response : any
}

interface ApiErrorResponse
{
	status : false,
	error : string,
	code : number
}

type ApiResponse = ApiSuccessResponse | ApiErrorResponse;

class ApiError extends Error
{
	public code : number;

	public constructor(message : string, code : number) {
		super(message);
		this.code = code;
	}

}

class ApiAxiosError extends ApiError
{

	public originalError : AxiosError;

	public constructor(e : AxiosError) {

		super(e.message, 500);

		this.originalError = e;

	}

}

export default class Api
{

	protected token  : string | null = null;
	protected domain : string;
	protected baseUrl = '/api/client';

	public constructor(domain : string) {
		this.domain = domain;
	}

	public setToken(token : string)  : this
	{
		this.token = token;
		return this;
	}

	public async login(initData : string){
		return this.request('/auth/login', {initData});
	}

	public async me(){
		return this.request('/profile/me');
	}

	public async setLanguage(code :string)
	{
		return this.request('/profile/set-language', {code});
	}

	public async tap(count : number){
		return this.request('/game/tap', { count });
	}

	public async energyBonusRecharge(){
		return this.request('/energy/bonus-recharge');
	}

	public async energyLevelUp(){
		return this.request('/energy/level-up');
	}

	public async rechargeEnergy(){
		return this.request('/energy/recharge');
	}

	public async marketInvoice(recharges : number){

	}

	public async tasks(){
		return this.request('/tasks/list');
	}

	public async taskCheck(id : number){
		return this.request('/tasks/check/' + id);
	}

	public async request(method : string, data : Record<string, any> = {}) : Promise<any> {

		try {

			let headers : Record<string, string> = {};

			if(this.token){
				headers['Authorization'] = `Bearer ${this.token}`;
			}

			let request = await axios.post(method, data, {
				baseURL : this.domain + this.baseUrl,
				headers
			});

			let response : ApiResponse = request.data;

			if(response.status){
				return response.response;
			}

			throw new ApiError(response.error, response.code);

		}catch (e : any){

			if(e instanceof AxiosError && e.message === 'Request aborted'){
				return new Promise(() => {});
			}else {
				throw e instanceof AxiosError ? new ApiAxiosError(e) : e;
			}
		}


	}

}