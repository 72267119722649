import {ref} from "vue";

export const useThrottler = function(time : number, immediate : boolean = true){

	let lastDate = ref<number>(immediate ? 0 : Date.now());

	return (fn : (...args : any[]) => any) => {

		let now = Date.now();

		if(now - time > lastDate.value){
			lastDate.value = now;
			fn();
		}

	}

}