import {defineStore} from "pinia";
import {ref} from "vue";
import Random from "@/modules/Random";

interface DestroyElement{
	n : number,
	v : number
}

export const useBackgroundStore = defineStore('background-store', () => {

	let destroyElements = ref<DestroyElement[]>([]);

	return {
		destroyElements,
		addDestroyElement(){

			destroyElements.value.push({
				n : Random.int(1, 4),
				v : Random.int(1, 7)
			});

		},
		clearDestroyElements(){

			destroyElements.value = [];

		}
	}

});