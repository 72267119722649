import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData{
	menu : {
		earn : string,
		boost : string,
		friends : string
	}
}

export const VMenuLanguages : LangList<Lang> = {
	[Languages.EN] : {
		menu : {
			earn : 'Earn',
			boost : 'Boost',
			friends : 'Friends'
		}
	},
	[Languages.RU] : {
		menu : {
			earn : 'Заработать',
			boost : 'Улучшения',
			friends : 'Друзья'
		}
	}
}