import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";
import Formatter from "@/modules/Formatter";

interface Lang extends LangData
{
	title : string,
	hint : string,
	panel : (v : number) => string,
	button : string
}

export const VModalNoFundsLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Нет баланса!',
		hint : 'Пригласи друзей и получи монеты прямо сейчас',
		panel : (v) => `Получи до <b>${ Formatter.numberFormat(v, 0) }</b>, когда ваши друзья присоединяться`,
		button : 'Получить'
	},
	[Languages.EN] : {
		title : 'No money!',
		hint : 'Invite friends and get rewards!',
		panel : (v) => `Get <b>${ Formatter.numberFormat(v, 0) }</b>, when your friends coming`,
		button : 'Get'
	}
};