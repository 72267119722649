<script lang="ts" setup>

import VMenu from "@/components/Game/VMenu.vue";
import VModalSettings from "@/components/Modals/VModalSettings.vue";
import {ref} from "vue";
import VGame from "@/components/Game/VGame.vue";

let showSettingsModal = ref<boolean>(false);

</script>
<template>
  <div class="main-screen">

	  <VGame />
	  <VMenu class="main-screen__menu" />
	  <button class="main-screen__settings" @click.prevent="showSettingsModal = true"></button>


	  <VModalSettings :active="showSettingsModal" @close="showSettingsModal = false" />
  </div>
</template>
