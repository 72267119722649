import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";
import Formatter from "@/modules/Formatter";

interface Lang extends LangData
{
	title : string,
	hint : string,
	panel : (v : number) => string,
	button : string
}

export const VModalNoEnergyLanguages : LangList<Lang> = {
	[Languages.RU] : {
		title : 'Нет энергии!',
		hint : 'Зарядите свою энергию до предела и сделайте еще одну попытку',
		panel : (v) => `Получи до <b>${ Formatter.numberFormat(v, 0) }</b>, когда ваши друзья присоединяться`,
		button : 'Получить'
	},
	[Languages.EN] : {
		title : 'No energy!',
		hint : 'Recharge your energy to the limit and try again.',
		panel : (v) => `Get <b>${ Formatter.numberFormat(v, 0) }</b>, when your friends coming`,
		button : 'Get'
	}
};