import {ref} from "vue";
import {TimeMachineTimeoutResult, useTimeMachine} from "@/composible/time-machine";

export const useDebounce = function(time : number){

	const timeMachine = useTimeMachine();

	let timeout = ref<TimeMachineTimeoutResult | null>(null);

	return (fn : (...args : any[]) => any) => {

		if(timeout.value){
			timeout.value.stop();
		}

		timeout.value = timeMachine.timeout(fn, time);

	}

}