<script setup lang="ts">

import VModal, {ModalProps} from "@/components/Utility/Modal/VModal.vue";
import {useLanguage} from "@/composible/language";
import {VModalNoFundsLanguages} from "@/langs/VModalNoFunds.lang";
import {VModalNoEnergyLanguages} from "@/langs/VModalNoEnergy.lang";
import {useGameStore} from "@/stores/game-store";
import VModalBuyEnergy from "@/components/Modals/VModalBuyEnergy.vue";
import {ref} from "vue";

const gameStore = useGameStore();

const lang = useLanguage(VModalNoEnergyLanguages);

const props = withDefaults(defineProps<Pick<ModalProps, 'active'>>(), {});

const emit = defineEmits(['close', 'open-market']);

async function recharge(){

	if(gameStore.user!.energy_charges > 0) {

		gameStore.user!.energy_charges--;
		gameStore.user!.energy = gameStore.user!.energy_max;

		try {
			await gameStore.api.rechargeEnergy();
		} catch (e) {
		}

		emit('close');

	}else{

		emit('open-market');

	}


}

</script>
<template>
	<div>
		<VModal v-bind="props" size="normal" @close="emit('close')">
			<div class="no-funds">
				<p class="no-funds__h">{{ lang.title }}</p>
				<p class="no-funds__hint">{{ lang.hint }}</p>
				<a href="#" @click.prevent="recharge" class="no-funds__button button button--big button--green">{{ lang.button }}</a>
			</div>
		</VModal>
	</div>
</template>
