<script lang="ts" setup>

import {computed, ref} from "vue";
import {useTimeMachine} from "@/composible/time-machine";

interface LoaderSlideProps{
	text : string,
	picture : string,
	id : string
}

const timeMachine = useTimeMachine();

const props = defineProps<LoaderSlideProps>();
const emit = defineEmits(['showed']);

let pictureLoaded = ref<boolean>(false);

let crops = computed<string[]>(() => {
	return props.text
		.replace(/:wallet/g, '<i></i>')
		.split(' ');
});

function animate(){

	timeMachine.timeout(() => {

		pictureLoaded.value = true;

		timeMachine.timeout(() => {

			pictureLoaded.value = false;

			timeMachine.timeout(() => emit('showed'), 500)

		}, 3000);

	}, 200);
}

function skip(){
	emit('showed');
}

</script>
<template>
	<div class="loader-slide" :class="[{'loader-slide--hidden' : !pictureLoaded}, `loader-slide--${props.id}`]">
		<div class="loader-slide__text">
			<span v-for="(crop,i) in crops" :key="i"
			      class="loader-slide__text-in"
			      :class="i > crops.length / 2 ? 'loader-slide__text-in--right' : 'loader-slide__text-in--left'"
			      v-html="crop" />
		</div>
		<img :src="props.picture" class="loader-slide__picture" @load="animate" @error="skip">
	</div>
</template>