<script type="text/javascript" lang="ts" setup>

import {onMounted, ref, watch} from "vue";

const emit = defineEmits(['load']);

let slot = ref<HTMLElement | null>(null);
let iterator = ref<number>(0);

function onLoad(){
	iterator.value--;
}

watch(iterator, (v) => {
	v > 0 || emit('load')
});

onMounted(() => {
	iterator.value = slot.value!.children.length;
})

</script>
<template>
	<div class="resources" ref="slot">
		<slot :onLoad="onLoad"></slot>
	</div>
</template>