export default class Formatter
{

	public static numberFormat(num : number, decCount : number = 0, decimalSymbol : string = '.', thousandSymbol : string = ' ') : string
	{

		let f = num.toFixed(decCount),
			d = decCount > 0 ? f.split('.').pop() : '',
			c = parseInt(<any>num).toString();

		let s = '';
		for(let i = 0; i < c.length; i++){

			if(!(i % 3)){
				s = thousandSymbol + s;
			}

			s = c[c.length - i - 1] + s;

		}

		return decCount > 0 ? (s + decimalSymbol + d) : s;

	}

}