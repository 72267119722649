<script lang="ts" setup>

import {useGameStore} from "@/stores/game-store";
import copy from "copy-to-clipboard";
import {useDebounce} from "@/composible/debounce";
import {ref} from "vue";
import {useLanguage} from "@/composible/language";
import {VFriendsLanguages} from "@/langs/VFriends.lang";
import {useClickHints} from "@/composible/click-hints";
import TelegramWebApp from "@/telegram/TelegramWebApp";

const gameStore = useGameStore();
const clickHints = useClickHints();
const lang = useLanguage(VFriendsLanguages);

let copied = ref<boolean>(false);

let copyDebounce = useDebounce(600);

function copyInviteLink(event : MouseEvent){

	clickHints.push(event.clientX - 40, event.clientY - 40, lang.value.copy);

	copy(gameStore.game!.friends.inviteLink!);

	copied.value = true;

	copyDebounce(() => {
		copied.value = false;
	});

}

function forwardMessage(){

	let text = gameStore.user!.lang === 'ru'
		? gameStore.game!.friends.inviteMessage.ru
		: gameStore.game!.friends.inviteMessage.en;

	TelegramWebApp.app!.openTelegramLink('https://t.me/share/url?url=' + encodeURIComponent(gameStore.game!.friends.inviteLink) + '&text=' + encodeURIComponent(text));
}

</script>
<template>
	<section class="friends background">
		<p class="friends__h">{{ lang.title }}: <span class="friends__count">{{ gameStore.user!.invited_friends }}</span></p>
		<h1 class="friends__hint">{{ lang.hint }}</h1>
		<div class="friends__body card card--normal">
			<article class="friends__variant friends-variant">
				<i class="friends-variant__icon friends-variant__icon--usual"></i>
				<p class="friends-variant__h">{{ lang.invite.usual.title }}</p>
				<p class="friends-variant__hint"><b class="friends-variant__price">{{ gameStore.game!.friends.reward }}</b> {{ lang.invite.usual.hint }}</p>
			</article>
			<article class="friends__variant friends-variant">
				<i class="friends-variant__icon friends-variant__icon--premium"></i>
				<p class="friends-variant__h">{{ lang.invite.premium.title }}</p>
				<p class="friends-variant__hint"><b class="friends-variant__price">{{ gameStore.game!.friends.rewardPremium }}</b> {{ lang.invite.premium.hint }}</p>
			</article>
			<div class="friends__footer">
				<button class="friends__button-invite button button--green button--big" @click="forwardMessage">{{ lang.button }}</button>
				<button class="friends__button-copy button button--small" :class="{ 'button--blue' : !copied, 'button--green' : copied }" @click.prevent="copyInviteLink"></button>
			</div>
		</div>
	</section>
</template>
