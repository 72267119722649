import {defineStore} from "pinia";
import {ref} from "vue";
import {useTimeMachine} from "@/composible/time-machine";

interface ClickHintsItem{
	text : string,
	size : number,
	x : number,
	y : number,
	id : string
}

export const useClickHintsStore = defineStore('click-hints-store', () => {

	const timeMachine = useTimeMachine();

	let list = ref<ClickHintsItem[]>([]),
		increment = ref<number>(0);

	return {
		increment,
		list,
		push(
			x : number,
			y : number,
			text : string,
			size : number = 14
		){

			let item = {
				y, x, text, size,
				id : Date.now() + '_' + Math.random()
			};

			list.value.push(item);

			timeMachine.timeout(() => {
				list.value.splice(list.value.indexOf(item), 1);
			}, 1000);

		}
	}


});