import {LangData, LangList} from "@/composible/language";
import Languages from "@/consts/languages";

interface Lang extends LangData{
	title : string
}

export const VQRLanguages : LangList<Lang> = {
	[Languages.EN] : {
		title : 'The game is available from a mobile device'
	},
	[Languages.RU] : {
		title : 'Игра доступна с мобильного устройства'
	}
}