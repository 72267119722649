<script lang="ts" setup>

import {Task} from "@/consts/models";
import VTask from "@/components/Tasks/VTask.vue";
import {onMounted, ref} from "vue";
import {useGameStore} from "@/stores/game-store";
import {useLanguage} from "@/composible/language";
import {VTasksLanguages} from "@/langs/VTasks.lang";

const gameStore = useGameStore();
const lang = useLanguage(VTasksLanguages);

let tasks = ref<Task[]>([]);
let loading = ref<boolean>(true);

function onTaskListLoaded(json : any){
	tasks.value = json.tasks;
	loading.value = false;
}

onMounted(async () => {

	try{
		tasks.value = await gameStore.api.tasks();
	}catch (e){

	}

	loading.value = false;

});


</script>
<template>
	<div class="tasks background">
		<h1 class="tasks__h">{{ lang.title }}</h1>
		<p class="tasks__hint">{{ lang.hint }}</p>
		<div class="tasks__body">
			<template v-if="loading">
				<div class="card card--small">
					<div class="coming-soon">{{ lang.loading }}</div>
				</div>
			</template>
			<template v-else-if="!tasks.length">
				<div class="card card--small">
					<div class="coming-soon">{{ lang.empty }}</div>
				</div>
			</template>
			<template v-else>
				<VTask :task="task"
				       v-for="task in tasks"
				       :key="task.id" />
			</template>
		</div>
	</div>
</template>