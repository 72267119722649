<script lang="ts" setup>

import Formatter from "@/modules/Formatter";
import {useGameStore} from "@/stores/game-store";

const gameStore = useGameStore();

</script>

<template>
	<div class="balance">
		<i class="balance__icon"></i>
		<span class="balance__value">{{ Formatter.numberFormat(gameStore.user!.balance, 0) }}</span>
	</div>
</template>