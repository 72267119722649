<script lang="ts" setup>

import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {SpinePlayer} from "@esotericsoftware/spine-player";

interface SpinePlayerProps
{
	skeleton : string,
	atlas : string,
	animation : string,
	playing? : boolean
}

const emit = defineEmits(['loaded']);

const props = withDefaults(defineProps<SpinePlayerProps>(), {
	playing : true
});

let el = ref<HTMLElement | null>(null);
let player = ref<SpinePlayer | null>(null);

let ready = ref<boolean>(false);
let error = ref<boolean>(false);

watch(ready, () => emit('loaded'));

onMounted(() => {

	try {

		player.value = new SpinePlayer(el.value!, {
			skeleton: props.skeleton,
			atlas: props.atlas,
			preserveDrawingBuffer: false,
			showLoading: false,
			showControls: false,
			alpha: true,
			animation: props.animation,
			draw(){
				if(!ready.value) {
					ready.value = true;
				}
			},
			success(instance) {

				console.log('Success');

				if (!props.playing) {
					instance.stopRendering();
				}


			},
			error(e) {
				player.value = null;
				error.value = true;

			}
		});

	}catch (e){
		error.value = true;
		player.value = null;

		console.log(e);

	}

});

onBeforeUnmount(() => {
	player.value?.dispose();
});

watch(() => props.playing, (v) => {
	v ? player.value?.startRendering() : player.value?.stopRendering();
})

</script>
<template>
	<div id="spine-player" v-if="!error" ref="el"></div>
</template>