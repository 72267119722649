<script setup lang="ts">

import VModal, {ModalProps} from "@/components/Utility/Modal/VModal.vue";

const props = withDefaults(defineProps<Pick<ModalProps, 'active'>>(), {});

</script>
<template>
	<VModal v-bind="props" size="small">
		<div class="coming-soon">Coming soon</div>
	</VModal>
</template>
