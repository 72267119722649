import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import VHomeView from '../views/VHomeView.vue'
import VGameLayout from "@/layouts/VGameLayout.vue";
import VFriendsView from "@/views/VFriendsView.vue";
import VBoostsView from "@/views/VBoostsView.vue";
import VTasksView from "@/views/VTasksView.vue";
import TelegramWebApp from "@/telegram/TelegramWebApp";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: VGameLayout,
    children : [
      {
        path : '',
        component : VHomeView
      },
      {
        path : '/friends',
        component : VFriendsView,
        meta : { showBackButton : true }
      },
      {
        path : '/boosts',
        component : VBoostsView,
        meta : { showBackButton : true }
      },
      {
        path : '/earn',
        component : VTasksView,
        meta : { showBackButton : true }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

if(TelegramWebApp.initiated){

  router.afterEach((to) => {

    if(to.meta.showBackButton){
      TelegramWebApp.app!.BackButton.show();
    }else{
      TelegramWebApp.app!.BackButton.hide();
    }

  });

  TelegramWebApp.app!.BackButton.onClick(() => router.back());

}

export default router
