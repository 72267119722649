export default class Random
{

	public static int(min : number, max : number) : number
	{
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	public static float(min : number, max : number) : number
	{
		return Math.random() * (max - min) + min;
	}

	public static array<T>(arr : T[]) : T
	{
		return arr[Random.int(0, arr.length - 1)];
	}

}